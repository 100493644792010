import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/functions";

// Your app's Firebase configuration
// var firebaseConfig = {
// 	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
// 	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
// 	databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
// 	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
// 	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
// 	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
// 	appId: process.env.REACT_APP_FIREBASE_APP_ID
// };

const firebaseConfig = {
	apiKey: "AIzaSyDoJz5orZmtqtceOMXHoZ03GpaN_oFlQu8",
	authDomain: "great-dad-ef2a5.firebaseapp.com",
	projectId: "great-dad-ef2a5",
	storageBucket: "great-dad-ef2a5.appspot.com",
	messagingSenderId: "428825923619",
	appId: "1:428825923619:web:285d1d9795bb7695f2a196",
	measurementId: "G-NGEMRJSBRP"
};

// Initialize Firebase
if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();
const db = firebase.firestore();
const functions = firebase.functions();

if (process.env.NODE_ENV === "development" && false) {
	auth.useEmulator("http://localhost:9099");
	db.useEmulator("localhost", 8080);
	functions.useEmulator("localhost", 5001);
}

export { auth, db, functions };

export default firebase;
