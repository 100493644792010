import { useState, useEffect } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import Navigation from "../navigation";
import "../../App.scss";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from "react-router-dom";
import Home from "pages/Home";
import Login from "pages/Login";
import Register from "pages/Register";
import { auth } from "../../firebase/firebase";
import { useAuthentication } from "../../firebase/auth";
import PasswordChange from "pages/PasswordChange";
import PasswordReset from "pages/PasswordReset";
import { messaging, getToken } from "../../firebase/messaging";
import { useSelector, useDispatch } from "react-redux";
import { setStatus, setUser, setData } from "features/authentication/authSlice";
import { removeChecklists } from "features/checklist/checklistSlice";

function Main() {
	const status = useSelector((state) => state.authentication.status);
	const user = useSelector((state) => state.authentication.user);
	const userData = useSelector((state) => state.authentication.data);

	const [show, setShow] = useState(false);

	const [message, setMessage] = useState({
		title: "",
		body: ""
	});

	const { getUserDetails } = useAuthentication();
	const dispatch = useDispatch();

	useEffect(() => {
		getToken().then((token) => {
			if (token) {
				messaging.messaging?.onMessage((payload) => {
					setMessage({
						title: payload.notification.title,
						body: payload.notification.body
					});
					setShow(true);
				});
			}
		});
	}, []);

	useEffect(() => {
		console.log(user);
	}, [user]);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((authUser) => {
			console.log("auth state changed");
			if (authUser !== null) {
				console.log("setting user UID");
				dispatch(setUser(authUser.uid));
			}
		});
		return () => {
			unsubscribe();
		};
	});

	useEffect(async () => {
		console.log("getting user details");
		if (user) {
			if (status === "authenticated") {
				if (Object.keys(userData).length === 0) {
					const data = await getUserDetails();
					dispatch(setData(data));
				} else {
					console.log("user data is not empty");
				}
			} else {
				console.log("status is not authenticated");
			}
		} else {
			console.log("user is null");
		}
	}, [user, status, userData]);

	return (
		<>
			<ToastContainer position='top-end' className='p-2'>
				<Toast onClose={() => setShow(false)} show={show}>
					<Toast.Header className='d-flex justify-content-between'>
						<strong className=''>{message.title}</strong>
					</Toast.Header>
					<Toast.Body>{message.body}</Toast.Body>
				</Toast>
			</ToastContainer>
			<Router>
				<Navigation auth={user} />
				<div className='main'>
					<Switch>
						<PrivateRoute exact auth={user} path='/'>
							<Home userData={userData} userId={user} />
						</PrivateRoute>
						<PrivateRoute exact auth={user} path='/password-change'>
							<PasswordChange auth={auth} />
						</PrivateRoute>
						<PublicRoute auth={user} path='/login'>
							<Login />
						</PublicRoute>
						<PublicRoute auth={user} path='/password-reset'>
							<PasswordReset auth={auth} />
						</PublicRoute>
						{/* 404 page */}
						<Route path='*'>
							<div className='d-flex justify-content-center align-items-center'>
								<h1>404</h1>
							</div>
						</Route>
					</Switch>
				</div>
			</Router>
		</>
	);
}

const PublicRoute = (props) => {
	const authentication = useSelector((state) => state.authentication);
	const { auth, children, ...rest } = props;
	return (
		<Route
			{...rest}
			render={({ location }) =>
				auth &&
				(props.path === "/login" ||
					(props.path === "/register" &&
						!["registering", "error"].includes(authentication.status))) ? (
					<Redirect
						to={{
							pathname: "/",
							state: { from: location }
						}}
					/>
				) : (
					children
				)
			}
		/>
	);
};

const PrivateRoute = (props) => {
	const { auth, children, ...rest } = props;
	return (
		<Route
			{...rest}
			render={({ location }) =>
				auth ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: location }
						}}
					/>
				)
			}
		/>
	);
};

export default Main;
