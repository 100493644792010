/* eslint-disable no-useless-escape */

import React, { useState, useEffect, forwardRef, useCallback } from "react";
import { db } from "../../firebase/firebase";
import marked from "marked";
import dayjs from "dayjs";
import { upperFirst } from "lodash";
import Spinner from "../common/Spinner";
import { getGuid } from "helpers";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DatePicker from "react-datepicker";
import { Dropdown } from "react-bootstrap";

import useScreenSize from "helpers/useScreenSize";
import useDeviceDetect from "helpers/useDeviceDetect";

import { useDispatch, useSelector } from "react-redux";
import { changeChecklists } from "./checklistSlice";
import { useTimer } from "features/timer/hooks";

const Checklist = (props) => {
	const {
		userId,
		userData,
		checklistId,
		checklistIndex,
		templates,
		backlogs,
		checklists
	} = props;
	const status = useSelector((state) => state.authentication.status);
	const [loading, setLoading] = useState(true);
	const [checklist, setChecklist] = useState({});

	const screenSize = useScreenSize();

	const dispatch = useDispatch();

	// TODO - Fix the title issue... does it need to have useEffect?
	// TODO - If 'day' fails, how do we try 'daily'? And what happens if both fail?

	const newChecklist = (checklistData) => {
		db.collection("accounts")
			.doc(userData.accounts.default)
			.collection("users")
			.doc(userId)
			.collection("checklists")
			.doc(checklistId)
			.set({
				type: checklistData.type,
				title: "",
				timestamp: Date.now(),
				checklist: [],
				status: "live"
			});
	};

	const updateExternalChecklist = (item, externalChecklistId) => {
		function sendItemToChecklist(currentChecklist) {
			db.collection("accounts")
				.doc(userData.accounts.default)
				.collection("users")
				.doc(userId)
				.collection("checklists")
				.doc(externalChecklistId)
				.set(currentChecklist, { merge: true });
		}
		if (externalChecklistId && item) {
			var docRef = db
				.collection("accounts")
				.doc(userData.accounts.default)
				.collection("users")
				.doc(userId)
				.collection("checklists")
				.doc(externalChecklistId);

			docRef
				.get()
				.then((doc) => {
					if (doc.exists) {
						const currentChecklist = { ...doc.data() };
						currentChecklist.checklist.push(item);
						sendItemToChecklist(currentChecklist);
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
					}
				})
				.catch((error) => {
					console.log("Error getting document:", error);
				});
		}
	};

	const updateChecklist = (data) => {
		db.collection("accounts")
			.doc(userData.accounts.default)
			.collection("users")
			.doc(userId)
			.collection("checklists")
			.doc(checklistId)
			.set(data, { merge: true });
	};

	const getChecklistData = () => {
		const checklistTypes = {
			daily: "daily",
			day: "daily",
			weekly: "week",
			week: "week",
			monthly: "month",
			month: "month",
			yearly: "year",
			year: "year",
			backlog: "backlog",
			template: "template"
		};
		const checklistData = {};
		checklistData.type = checklistTypes[checklistId.split("-")[0]];

		if (checklistData.type === "template" || checklistData.type === "backlog") {
			return checklistData;
		}

		checklistData.date = dayjs(
			checklistId
				.split("-")[1]
				.replace(/([0-9]{2})([0-9]{2})([0-9]{4})/g, `$3-$1-$2`)
		);
		return checklistData.type && checklistData.date ? checklistData : false;
	};

	const changeChecklistClicked = (direction) => {
		setChecklist({});

		const checklistData = getChecklistData();
		let newChecklistDate;
		const checklistDateType =
			checklistData.type === "daily" ? "day" : checklistData.type;

		if (direction === "down") {
			newChecklistDate =
				checklistData.type +
				"-" +
				dayjs(checklistData.date)
					.subtract(1, checklistDateType)
					.format("MMDDYYYY");

			dispatch(
				changeChecklists({ value: newChecklistDate, index: checklistIndex })
			);
		} else {
			dispatch(
				changeChecklists({
					value:
						checklistData.type +
						"-" +
						dayjs(checklistData.date)
							.add(1, checklistDateType)
							.format("MMDDYYYY"),
					index: checklistIndex
				})
			);
		}
	};

	useEffect(() => {
		setLoading(false);
	}, [checklist]);

	useEffect(() => {
		let unsubscribe = new Function();
		if (
			userData.accounts?.default &&
			userId &&
			!["template", "backlog"].includes(checklistId) &&
			status === "authenticated"
		) {
			setLoading(true);
			const dbRef = db
				.collection("accounts")
				.doc(userData.accounts.default)
				.collection("users")
				.doc(userId)
				.collection("checklists");

			unsubscribe = dbRef.doc(checklistId).onSnapshot((snapshot) => {
				if (snapshot?.data()?.timestamp) {
					setChecklist(snapshot.data());
				} else {
					newChecklist(getChecklistData());
				}
			});
		} else {
			setLoading(false);
			setChecklist({});
		}
		return () => {
			unsubscribe();
		};
	}, [userData, userId, checklistId, status]);

	useEffect(() => {
		if (status !== "authenticated") {
			setChecklist({});
		}
	}, [status]);

	if (!userData.accounts?.default) {
		return <Spinner />;
	}

	return (
		<>
			<div
				className={`checklist-container ${
					screenSize !== "large" ? "is-mobile" : ""
				}`}
			>
				<ChecklistHeader
					changeChecklistClicked={changeChecklistClicked}
					checklistId={checklistId}
					checklistIndex={checklistIndex}
					updateChecklist={updateChecklist}
					checklist={checklist}
				/>
				{["backlog", "template"].includes(checklistId) ? (
					<ChooseGeneric
						checklistIndex={checklistIndex}
						checklistId={checklistId}
						userId={userId}
						userData={userData}
						templates={templates}
						backlogs={backlogs}
					/>
				) : (
					<>
						<div className='checklist-items'>
							{loading || !checklist.timestamp ? <Spinner /> : null}
							{!loading && checklist.timestamp && checklist.checklist ? (
								checklist.checklist?.length ? (
									<ChecklistItems
										checklist={checklist}
										updateChecklist={updateChecklist}
										checklists={checklists}
										checklistIndex={checklistIndex}
										updateExternalChecklist={updateExternalChecklist}
									/>
								) : (
									<ChooseTemplate
										checklist={checklist}
										updateChecklist={updateChecklist}
										templates={templates}
									/>
								)
							) : null}
						</div>
						<div className='checklist-input-container'>
							<ChecklistInput
								checklist={checklist}
								updateChecklist={updateChecklist}
							/>
						</div>
					</>
				)}
			</div>
		</>
	);
};

const ChooseTemplate = (props) => {
	const { checklist, updateChecklist, templates } = props;

	const addTemplateToChecklist = (id) => {
		const items = templates.filter((template) => template.id === id);
		if (items.length > 0) {
			const newChecklist = { ...checklist };
			newChecklist.checklist = [...items[0].checklist];
			updateChecklist(newChecklist);
		} else {
			console.log("Hmmm... couldn't find that template?");
		}
	};

	return (
		<div className='p-3'>
			<h6 className='text-center mt-3'>
				Pick a template or start adding tasks!
			</h6>
			{templates.length === 0 ? (
				<div>Doesn't look like you have any templates!</div>
			) : (
				<div className='list-group mt-3 choose-checklist'>
					{templates.map((template, index) => {
						return (
							<button
								type='button'
								className='list-group-item list-group-item-action'
								key={index}
								onClick={() => {
									addTemplateToChecklist(template.id);
								}}
							>
								{template.title.length ? template.title : template.id}
							</button>
						);
					})}
				</div>
			)}
		</div>
	);
};

const ChecklistHeader = (props) => {
	const {
		checklist,
		changeChecklistClicked,
		checklistId,
		checklistIndex,
		updateChecklist
	} = props;

	const [checklistTitle, setChecklistTitle] = useState("Loading");
	const [checklistType, setChecklistType] = useState("");
	const [inputText, setInputText] = useState(checklistTitle);
	const [editing, setEditing] = useState(false);
	const [startDate, setStartDate] = useState(new Date());

	const dispatch = useDispatch();

	const getChecklistType = () => {
		if (checklistId?.length) {
			return checklistId.split("-")[0].toLowerCase();
		} else {
			return "";
		}
	};

	const getChecklistTitle = () => {
		const titleInfo = checklistId.split("-");
		if (titleInfo.length === 1) {
			return "";
		}

		if (["backlog", "template"].includes(checklistId.split("-")[0])) {
			return checklist.title ?? "";
		}

		const dateReformatted = titleInfo[1].replace(
			/([0-9]{2})([0-9]{2})([0-9]{4})/g,
			`$3-$1-$2`
		);

		let titleFormat;
		switch (titleInfo[0]) {
			case "daily":
				titleFormat = "dddd, MMMM Do YYYY";
				break;
			case "day":
				titleFormat = "dddd, MMMM Do YYYY";
				break;
			case "week":
				titleFormat = "MMMM Do YYYY";
				break;
			case "month":
				titleFormat = "MMMM YYYY";
				break;
			case "year":
				titleFormat = "YYYY";
				break;
			default:
				titleFormat = "dddd, MMMM Do YYYY";
				break;
		}

		return dayjs(dateReformatted).format(titleFormat);
	};

	const changeTitle = (event) => {
		event.preventDefault();
		if (inputText.length > 0 && inputText !== checklistTitle) {
			const newChecklist = { ...checklist };
			newChecklist.title = inputText;
			updateChecklist(newChecklist);
		}
		setEditing(false);
	};

	const closeChecklist = () => {
		dispatch(changeChecklists({ value: false, index: checklistIndex }));
	};

	const updateChecklistStatus = (status) => {
		const newChecklist = { ...checklist };
		newChecklist.status = status;
		updateChecklist(newChecklist);
	};

	const changeChecklistType = (newType) => {
		let newChecklistId;

		switch (newType) {
			case "day":
				newChecklistId = `daily-${dayjs().format("MMDDYYYY")}`;
				break;
			case "week":
				newChecklistId = `week-${dayjs().weekday(0).format("MMDDYYYY")}`;
				break;
			case "month":
				newChecklistId = `month-${dayjs().date(1).format("MMDDYYYY")}`;
				break;
			case "year":
				newChecklistId = `year-${dayjs().dayOfYear(1).format("MMDDYYYY")}`;
				break;
			case "template":
				newChecklistId = `template`;
				break;
			case "backlog":
				newChecklistId = `backlog`;
				break;
			default:
				newChecklistId = null;
				break;
		}

		newChecklistId &&
			dispatch(
				changeChecklists({ value: newChecklistId, index: checklistIndex })
			);
	};

	const DatePickerButton = forwardRef(({ value, onClick }, ref) => (
		<button className='btn btn-light btn-sm me-1' onClick={onClick} ref={ref}>
			<i className='bi-calendar3'></i>
		</button>
	));

	useEffect(() => {
		setChecklistTitle(getChecklistTitle);
		setChecklistType(getChecklistType);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checklistId]);

	useEffect(() => {
		if (["backlog", "template"].includes(checklistId.split("-")[0])) {
			if (checklist.title !== checklistTitle) {
				setChecklistTitle(getChecklistTitle);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checklist]);

	useEffect(() => {
		editing && setInputText(checklistTitle);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editing]);

	return (
		<div className='checklist-header d-flex justify-content-between align-items-center'>
			{editing &&
			["backlog", "template"].includes(checklistId.split("-")[0]) &&
			checklistId.split("-").length === 2 ? (
				<form
					id={`${checklistId}-form-title`}
					onSubmit={(event) => changeTitle(event)}
					style={{ flex: 1 }}
				>
					<div className='input-group checklist-input'>
						<input
							className='form-control'
							type='text'
							autoFocus
							value={inputText}
							onChange={(e) => {
								setInputText(e.target.value);
							}}
							onKeyUp={(e) => {
								if (e.key === "Escape") {
									document
										.querySelector(`form#${checklistId}-form-title button`)
										.click();
								}
							}}
							onBlur={() => {
								const form = document.querySelector(
									`form#${checklistId}-form-title button`
								);

								form && form.click();
							}}
						/>
						<button type='submit' className='btn btn-primary'>
							Update Title
						</button>
					</div>
				</form>
			) : (
				<h1
					className='checklist-title'
					onClick={() => {
						["backlog", "template"].includes(checklistId.split("-")[0]) &&
							setEditing(true);
					}}
				>
					{`${upperFirst(checklistId.split("-")[0])}${
						checklistId.split("-").length === 1 ? "s" : ""
					}: ${checklistTitle}`}
				</h1>
			)}
			<div className='date-chooser-header d-flex ms-auto'>
				{!["backlog", "template"].includes(checklistId.split("-")[0]) &&
					!editing && (
						<>
							<button
								className='btn btn-light btn-sm me-1'
								onClick={() => {
									changeChecklistClicked("down");
								}}
							>
								<i className='bi-caret-left-fill'></i>
							</button>
							<button
								className='btn btn-light btn-sm me-1'
								onClick={() => {
									changeChecklistClicked("up");
								}}
							>
								<i className='bi-caret-right-fill'></i>
							</button>
							<DatePicker
								selected={startDate}
								onChange={(date) => {
									let newDate;
									if (checklistType === "week") {
										newDate = dayjs(date).weekday(0).format("MMDDYYYY");
									} else {
										newDate = dayjs(date).format("MMDDYYYY");
									}
									checklistId &&
										dispatch(
											changeChecklists({
												value: checklistType + "-" + newDate,
												index: checklistIndex
											})
										);
								}}
								customInput={<DatePickerButton />}
								showMonthYearPicker={checklistType === "month" ? true : false}
								showYearPicker={checklistType === "year" ? true : false}
								fixedHeight
								dateFormat='mmddyyyy'
								calendarStartDay={1}
								popperPlacement='bottom-end'
							/>
						</>
					)}
			</div>
			<Dropdown align='end' className='checklist-menu-dropdown'>
				<Dropdown.Toggle variant='light' size='sm'>
					<i className='bi-three-dots'></i>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Header>Change to...</Dropdown.Header>
					{["day", "week", "month", "year", "backlog", "template"].map(
						(type) => {
							return (
								<Dropdown.Item
									key={type + "-" + checklistIndex}
									onClick={() => {
										changeChecklistType(type);
									}}
								>
									{type.charAt(0).toUpperCase() + type.substr(1).toLowerCase()}
								</Dropdown.Item>
							);
						}
					)}
					<Dropdown.Divider />
					{(checklist.type === "backlog" || checklist.type === "template") && (
						<Dropdown.Item
							onClick={() => {
								updateChecklistStatus("archived");
							}}
						>{`Archive ${checklist.type}`}</Dropdown.Item>
					)}
					<Dropdown.Item
						onClick={() => {
							closeChecklist();
						}}
					>
						Close Checklist
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};

const ChecklistItems = (props) => {
	const {
		checklist,
		updateChecklist,
		checklists,
		checklistIndex,
		updateExternalChecklist
	} = props;

	const screenSize = useScreenSize();
	const { isTouch } = useDeviceDetect();

	const [expandedCategories, setExpandedCategories] = useState(new Set());

	const toggleCategory = useCallback(
		(index) => {
			const newExpandedCategories = new Set(expandedCategories);

			if (newExpandedCategories.has(index)) {
				newExpandedCategories.delete(index);
			} else {
				newExpandedCategories.add(index);
			}
			console.log("newExpandedCategories", newExpandedCategories);
			setExpandedCategories(newExpandedCategories);
		},
		[expandedCategories]
	);

	const getRelatedCategoryIndex = useCallback(
		(taskIndex) => {
			if (checklist?.checklist) {
				let categoryIndex = -1;
				for (let i = taskIndex; i >= 0; i--) {
					if (checklist.checklist[i].type === "section") {
						categoryIndex = i;
						break;
					}
				}
				console.log("categoryIndex", categoryIndex);
				return categoryIndex;
			}
		},
		[checklist]
	);

	// a little function to help us with reordering the result
	const reorder = (startIndex, endIndex) => {
		const result = { ...checklist };
		const [removed] = result.checklist.splice(startIndex, 1);
		result.checklist.splice(endIndex, 0, removed);

		return result;
	};

	const grid = 0;

	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: "none",
		padding: grid * 2,
		margin: `0 0 ${grid}px 0`,

		// change background colour if dragging
		background: isDragging ? "lightgreen" : "transparent",

		// styles we need to apply on draggables
		...draggableStyle
	});

	const getListStyle = (isDraggingOver) => ({
		background: isDraggingOver ? "transparent" : "transparent",
		padding: grid
	});

	const onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const reorderedItems = reorder(
			result.source.index,
			result.destination.index
		);

		updateChecklist(reorderedItems);
	};
	return (
		<ul className='list-group'>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId='droppable'>
					{(provided, snapshot) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={getListStyle(snapshot.isDraggingOver)}
						>
							{checklist.checklist.map((item, index) => {
								return (
									<Draggable
										key={item.key}
										draggableId={item.key}
										index={index}
									>
										{(provided, snapshot) => {
											return (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getItemStyle(
														snapshot.isDragging,
														provided.draggableProps.style
													)}
													className={["item-container"].join(" ")}
												>
													<ChecklistItem
														key={item.timeStamp}
														item={item}
														index={index}
														updateChecklist={updateChecklist}
														checklist={checklist}
														checklists={checklists}
														checklistIndex={checklistIndex}
														updateExternalChecklist={updateExternalChecklist}
														screenSize={screenSize}
														isTouch={isTouch}
														toggleCategory={toggleCategory}
														expandedCategories={expandedCategories}
														getRelatedCategoryIndex={getRelatedCategoryIndex}
													/>
												</div>
											);
										}}
									</Draggable>
								);
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</ul>
	);
};

// Find time in a task -- https://regexr.com/6rdd0
const findTimeRegex =
	/\(((\d+(h|m))|((\d+h)\s(\d+m)))(\s((\d+(m|s))\/(\d+(m|s))))?\)/;

const ChecklistItem = (props) => {
	const {
		item,
		index,
		checklist,
		updateChecklist,
		updateExternalChecklist,
		checklists,
		checklistIndex,
		isTouch,
		screenSize,
		toggleCategory,
		expandedCategories,
		getRelatedCategoryIndex
	} = props;

	const [taskFocus, setTaskFocus] = useState(false);
	const [taskHover, setTaskHover] = useState(false);
	const [editStatus, setEditStatus] = useState(false);
	const [renderedItem, setRenderedItem] = useState("");
	const [showActionItems, setShowActionItems] = useState(false);
	const [time, setTime] = useState(item.time ?? { total: 0, work: 0, rest: 0 });
	const [relatedSectionIndex, setRelatedSectionIndex] = useState(
		getRelatedCategoryIndex(index)
	);

	const { updateTimerSettings, updateTimerShow } = useTimer();

	const changeEditStatus = () => {
		setEditStatus(!editStatus);
	};

	const deleteItem = () => {
		const newChecklist = { ...checklist };
		newChecklist.checklist.splice(index, 1);
		updateChecklist(newChecklist);
	};

	const updateItemStatus = (status) => {
		const updatedChecklist = { ...checklist };
		updatedChecklist.checklist[index].status = status;
		updateChecklist(updatedChecklist);
	};

	const copyItemToChecklist = (checklistId) => {
		console.log("sending item to external list: ", checklistId);

		console.log(item);

		let newItem = { ...item, key: getGuid() };

		if (checklist.type === "backlog") {
			console.log("backlog item");
			newItem.text = `_${checklist.title}:_ ${newItem.text}`;
		}

		console.log(newItem);

		updateExternalChecklist(newItem, checklistId);
	};

	useEffect(() => {
		setRelatedSectionIndex(getRelatedCategoryIndex(index));
	}, [index]);

	useEffect(() => {
		let newItem = marked(item.text);
		// open links in new window
		newItem = newItem.replace(/<a href/, '<a target="_blank" href');
		// tag function (visual only for the moment)
		newItem = newItem.replace(
			/(@\w+)/g,
			`<span class='checklist-item-tag'>$1</span>`
		);
		// time function (visual only for the moment)
		newItem = newItem.replace(
			findTimeRegex,
			($1, $2, $3, $4, $5, $6, $7, $8, $9) => {
				return `<span class='checklist-item-duration'>${$2}</span>`;
			}
		);
		setRenderedItem(newItem);
	}, [item]);

	useEffect(() => {
		if (item.type === "task") {
			if (findTimeRegex.test(item.text)) {
				const time = findTimeRegex.exec(item.text);
				const seconds = time[3] === "s" ? parseInt(time[2]) : 0;
				const minutes = time[3] === "m" ? parseInt(time[2]) : 0;
				const hours = time[3] === "h" ? parseInt(time[2]) : 0;
				const total = seconds + minutes * 60 + hours * 3600;

				const workSeconds = time[10] === "s" ? parseInt(time[9]) : 0;
				const workMinutes = time[10] === "m" ? parseInt(time[9]) : 0;
				const workHours = time[10] === "h" ? parseInt(time[9]) : 0;
				const work = workSeconds + workMinutes * 60 + workHours * 3600;

				const restSeconds = time[12] === "s" ? parseInt(time[11]) : 0;
				const restMinutes = time[12] === "m" ? parseInt(time[11]) : 0;
				const restHours = time[12] === "h" ? parseInt(time[11]) : 0;

				const rest = restSeconds + restMinutes * 60 + restHours * 3600;

				setTime({ total, work, rest });
			}
		}
	}, [item]);

	const itemStyling =
		item.status === "complete"
			? "complete"
			: item.status === "in-progress"
			? "in-progress"
			: item.status === "failed"
			? "failed"
			: "";

	return (
		<li
			className={[
				"list-group-item d-flex align-items-center justify-content-between checklist-item",
				itemStyling,
				item.status === true && item.type !== "section" ? "complete" : "",
				item.type === "section" ? "section" : "",
				!expandedCategories.has(relatedSectionIndex) || item.type === "section"
					? ""
					: "collapsed"
			].join(" ")}
			key={item.timeStamp}
			onMouseOver={() => {
				setTaskHover(true);
			}}
			onMouseLeave={() => {
				setTaskHover(false);
			}}
			onFocus={() => {
				setTaskFocus(true);
			}}
			onBlur={() => {
				setTaskFocus(false);
			}}
			onKeyUp={(e) => {
				if (e.key === "Enter" && taskFocus) {
					changeEditStatus();
				}
			}}
		>
			{!editStatus ? (
				<>
					<div
						className='checklist-text'
						onClick={(e) => {
							if (e.target.tagName.toLowerCase() !== "a") {
								if (isTouch || screenSize !== "large") {
									setShowActionItems(!showActionItems);
								} else {
									changeEditStatus();
								}
							}
						}}
						dangerouslySetInnerHTML={{
							__html: renderedItem
						}}
					></div>
					{taskHover && screenSize === "large" && (
						<div className='ms-auto me-0 p-0 m-0 d-flex'>
							<button
								className={`btn p-0 ms-1 item-action trash-button ms-auto me-1`}
								type='button'
								id={`item-delete-${item.timeStamp}`}
								title='Item delete'
								onClick={() => {
									deleteItem();
								}}
							>
								<i className='bi-trash'></i>
							</button>
						</div>
					)}
					{(taskHover || isTouch || screenSize !== "large") && (
						<Dropdown
							id={`item-actions-${item.timeStamp}`}
							className='checklist-item-menu ms-auto me-0 p-0 m-0 d-flex'
							onToggle={() => {
								setShowActionItems(!showActionItems);
							}}
							show={showActionItems}
						>
							<Dropdown.Toggle
								variant='sm'
								className={screenSize !== "large" && "invisible"}
							>
								<i className='bi-three-dots p-0'></i>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item
									onClick={() => {
										changeEditStatus();
									}}
								>
									Edit item
								</Dropdown.Item>
								{time.total > 0 ? (
									<Dropdown.Item
										className='item'
										onClick={() => {
											updateTimerSettings({
												work: time.work > 0 ? time.work : time.total,
												rest: time.rest,
												cycles:
													time.work > 0
														? Math.floor(time.total / (time.work + time.rest))
														: 1
											});
											updateTimerShow(true);
										}}
									>
										Run Timer
									</Dropdown.Item>
								) : null}
								{checklists.map((checklistId) => {
									if (checklistId === checklists[checklistIndex]) {
										return null;
									}
									return (
										<Dropdown.Item
											key={`checklist-copy-${checklistId}`}
											onClick={() => {
												copyItemToChecklist(checklistId);
											}}
										>
											Copy to {checklistId.replace("-", " ")}
										</Dropdown.Item>
									);
								})}

								<Dropdown.Item
									className='item'
									onClick={() => {
										deleteItem();
									}}
								>
									Delete item
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					)}
					{item.type !== "section" ? (
						<ItemStatusButton
							item={item}
							updateItemStatus={updateItemStatus}
							index={index}
						/>
					) : (
						<button
							className={`btn p-0 ms-1 item-action ms-auto me-1`}
							type='button'
							id={`section-expand-${item.timeStamp}`}
							title='Section expand'
							onClick={() => {
								toggleCategory(relatedSectionIndex);
							}}
						>
							<i className='bi-caret-right'></i>
						</button>
					)}
				</>
			) : (
				<ChecklistInput
					checklist={checklist}
					updateChecklist={updateChecklist}
					taskIndex={index}
					changeEditStatus={changeEditStatus}
					screenSize={screenSize}
				/>
			)}
		</li>
	);
};

const ItemStatusButton = ({ item, updateItemStatus }) => {
	const changeItemStatus = () => {
		const statusTypes = ["todo", "in-progress", "complete", "failed"];

		const isInStatusTypes = statusTypes.includes(item.status);

		let currentStatusIndex;

		if (isInStatusTypes === true) {
			currentStatusIndex = statusTypes.indexOf(item.status);
		} else if (item.status === true) {
			currentStatusIndex = statusTypes.length - 1;
		} else {
			currentStatusIndex = 0;
		}

		let newStatus;

		if (currentStatusIndex === statusTypes.length - 1) {
			newStatus = statusTypes[0];
		} else {
			newStatus = statusTypes[currentStatusIndex + 1];
		}

		updateItemStatus(newStatus);
	};

	return (
		<button
			className='btn p-0 ms-1 status-button'
			onClick={() => {
				changeItemStatus();
			}}
			title={`Task status: ${item.status === false ? "To do" : item.status}`}
		>
			<i
				className={
					item.status === false || item.status === "todo"
						? "bi-square"
						: item.status === true || item.status === "complete"
						? "bi-check-square-fill"
						: item.status === "failed"
						? "bi-x-square-fill"
						: "bi-arrow-right-square-fill"
				}
			></i>
		</button>
	);
};

const ChecklistInput = (props) => {
	const {
		checklist,
		updateChecklist,
		taskIndex = false,
		changeEditStatus = null,
		screenSize
	} = props;

	const [inputText, setInputText] = useState(
		taskIndex !== false ? checklist.checklist[taskIndex].text : ""
	);

	const whatIsIt = () => {
		return inputText.charAt(0) !== "#" ? "task" : "section";
	};

	const handleInputSubmit = (event) => {
		event.preventDefault();
		let newChecklist;
		let itemText = inputText.trim();
		if (itemText.length > 0) {
			itemText = decodeTheText(itemText);
			if (taskIndex !== false) {
				if (
					itemText.length > 0 &&
					itemText !== checklist.checklist[taskIndex].text
				) {
					newChecklist = { ...checklist };
					newChecklist.checklist[taskIndex].text = itemText;
					updateChecklist(newChecklist);
				}
				changeEditStatus();
			} else {
				const nodeType = whatIsIt(itemText);
				newChecklist = { ...checklist };
				newChecklist.checklist.push({
					text: itemText,
					key: getGuid(10),
					timeStamp: Date.now(),
					type: nodeType,
					status: "todo"
				});
				updateChecklist(newChecklist);
			}
			taskIndex === false && setInputText("");
		} else {
			if (taskIndex !== false) {
				newChecklist = { ...checklist };
				newChecklist.checklist.splice(taskIndex, 1);
				updateChecklist(newChecklist);
			}
		}
	};

	const decodeTheText = (itemInput) => {
		// Links - Find industry standard links and convert into link markup
		// JIRA - Atlassian ticket
		itemInput = itemInput.replace(
			/((?!http[s]?:\/\/[a-z0-9\.\=\?\-\/\&\#]+\))(https\:\/\/[a-z0-9\-]+.atlassian.net\/browse\/)([a-z0-9\-]+))/gi,
			`[$3]($1)`
		);
		// JIRA - QuickView Modal
		itemInput = itemInput.replace(
			/((?!http[s]?:\/\/[a-z0-9\.\=\?\-\/\&\#]+\))(https:\/\/)([a-z0-9\-]+.atlassian.net\/secure\/RapidBoard.jspa\?)(rapidView=[a-z0-9\-]+)(&projectKey=[a-z0-9\-]+)?(&modal=detail)(&selectedIssue=)([a-z0-9\-]+))/gi,
			`[$8]($1)`
		);
		// Trello - Board Ticket
		itemInput = itemInput.replace(
			/((?!http[s]?:\/\/[a-z0-9\.\=\?\-\/\&\#]+\))(https\:\/\/trello.com\/c\/[a-z0-9\-]+\/)([a-z0-9\-]+))/gi,
			`[$3]($1)`
		);
		// Google Meet Link
		itemInput = itemInput.replace(
			/((?!http[s]?:\/\/[a-z0-9\.\=\?\-\/\&\#]+\))(https\:\/\/meet.google.com\/)([a-z0-9\-\?\=\&]+))/gi,
			`[Google Meet]($1)`
		);

		// Make sure headings are correctly formatted
		if (itemInput.charAt(0) === "#") {
			itemInput = itemInput.replace(/##(#+)/, "##");
			itemInput = itemInput.replace(/(#+)(\s?)(.)/gi, `$1 $3`);
		}

		return itemInput;
	};

	return (
		<form
			id={taskIndex !== false ? `A${checklist.checklist[taskIndex].key}` : ""}
			onSubmit={(event) => handleInputSubmit(event)}
			style={{ flex: 1 }}
		>
			<div className='input-group checklist-input'>
				<input
					className='form-control'
					type='text'
					autoFocus={screenSize === "large" ? true : false}
					value={inputText}
					onChange={(e) => {
						setInputText(e.target.value);
					}}
					onKeyUp={(e) => {
						if (e.key === "Escape") {
							taskIndex !== false
								? document
										.querySelector(
											`form#A${checklist.checklist[taskIndex].key} button`
										)
										.click()
								: e.target.blur();
						}
					}}
					onBlur={() => {
						if (taskIndex !== false) {
							const form = document.querySelector(
								`form#A${checklist.checklist[taskIndex].key} button`
							);

							form && form.click();
						}
					}}
					placeholder={
						taskIndex === false ? "Type here to add item, then press ENTER" : ""
					}
				/>
				<button type='submit' className='btn btn-primary'>
					{taskIndex !== false ? "Update" : "Add Task"}
				</button>
			</div>
		</form>
	);
};

const ChooseGeneric = (props) => {
	const { checklistIndex, checklistId, templates, backlogs } = props;

	const dispatch = useDispatch();

	const [type, setType] = useState([]);

	useEffect(() => {
		checklistId && setType(checklistId === "template" ? templates : backlogs);
	}, [checklistId, templates, backlogs]);

	const createNewGeneric = () => {
		dispatch(
			changeChecklists({
				value: `${checklistId}-${getGuid()}`,
				index: checklistIndex
			})
		);
	};

	return (
		<>
			<div className='list-group mt-3 mb-auto choose-checklist'>
				{type.length &&
					type.map((generic, index) => {
						return (
							<button
								type='button'
								className='list-group-item list-group-item-action'
								key={index}
								onClick={() => {
									dispatch(
										changeChecklists({
											value: `${checklistId}-${generic.id}`,
											index: checklistIndex
										})
									);
								}}
							>
								{generic.title.length ? generic.title : generic.id}
							</button>
						);
					})}
			</div>
			<button
				className='btn btn-primary'
				onClick={() => {
					createNewGeneric();
				}}
			>
				{`Create new ${checklistId}`}
			</button>
		</>
	);
};

export default Checklist;
